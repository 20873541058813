
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexfP14H4t8_45x0rpAkahI8aJJ617l2uTgCWnSTSziM5H2YMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/index.vue?macro=true";
import { default as index7TmG5RiSdt6bxoSor8x0FjGwamIS6Oo00J3dxkCMdngMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startwLmMgecrs10n4CMqMf1lqs48IAPZQO2jr1mW0H0jeXsMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startcQ_45LYa01IMWGPxVDLchgPtW5tXw_XfDahlRb8Zfw8RQMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkout0LirXmLfDNjCA46kHz0RaC31U9WK6gt_UldI9PUAS8UMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koP_hcV2dFYxTvmDc_GaJ5QCMt5BspUCgk67GoNoXBbNwMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationw8Lr_plCy0twBnhhpS0yT2_45uemOqM2EV2V4gXHHJ8yMMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationWEAuVU5NOEmUuq3NwS7P75SfC_JkvNU8eK_45EEenoq0QMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyer9vn8N9E61Zsu5pcHGimQqkzEaNoFS1tNgxnapg9MaqwMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerofHTsD0PTYgbe8q0AUMIQaX7nTFTtJlS3HGx270gqqYMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentC_45NUhPqmWyPupgiHOBgvt40ps7pZfcCc7nGS_453lxWjkMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengeL3_45KkAYf_45qQkw54ciHhGG_HrDkzcNGeP4X4bGVM4EPwMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentMEiUVvHUAS2HBIkMMVsPgIaZueqbxc2N7wIgeCbndzoMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta } from "/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexfP14H4t8_45x0rpAkahI8aJJ617l2uTgCWnSTSziM5H2YMeta?.name ?? "index",
    path: indexfP14H4t8_45x0rpAkahI8aJJ617l2uTgCWnSTSziM5H2YMeta?.path ?? "/",
    props: indexfP14H4t8_45x0rpAkahI8aJJ617l2uTgCWnSTSziM5H2YMeta?.props ?? false,
    meta: indexfP14H4t8_45x0rpAkahI8aJJ617l2uTgCWnSTSziM5H2YMeta || {},
    alias: indexfP14H4t8_45x0rpAkahI8aJJ617l2uTgCWnSTSziM5H2YMeta?.alias || [],
    redirect: indexfP14H4t8_45x0rpAkahI8aJJ617l2uTgCWnSTSziM5H2YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/index.vue")
  },
  {
    name: index7TmG5RiSdt6bxoSor8x0FjGwamIS6Oo00J3dxkCMdngMeta?.name ?? "lang",
    path: index7TmG5RiSdt6bxoSor8x0FjGwamIS6Oo00J3dxkCMdngMeta?.path ?? "/:lang?",
    props: index7TmG5RiSdt6bxoSor8x0FjGwamIS6Oo00J3dxkCMdngMeta?.props ?? false,
    meta: index7TmG5RiSdt6bxoSor8x0FjGwamIS6Oo00J3dxkCMdngMeta || {},
    alias: index7TmG5RiSdt6bxoSor8x0FjGwamIS6Oo00J3dxkCMdngMeta?.alias || [],
    redirect: index7TmG5RiSdt6bxoSor8x0FjGwamIS6Oo00J3dxkCMdngMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startwLmMgecrs10n4CMqMf1lqs48IAPZQO2jr1mW0H0jeXsMeta?.name ?? "lang-voucher-start",
    path: startwLmMgecrs10n4CMqMf1lqs48IAPZQO2jr1mW0H0jeXsMeta?.path ?? "/:lang?/voucher/start",
    props: startwLmMgecrs10n4CMqMf1lqs48IAPZQO2jr1mW0H0jeXsMeta?.props ?? false,
    meta: startwLmMgecrs10n4CMqMf1lqs48IAPZQO2jr1mW0H0jeXsMeta || {},
    alias: startwLmMgecrs10n4CMqMf1lqs48IAPZQO2jr1mW0H0jeXsMeta?.alias || [],
    redirect: startwLmMgecrs10n4CMqMf1lqs48IAPZQO2jr1mW0H0jeXsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startcQ_45LYa01IMWGPxVDLchgPtW5tXw_XfDahlRb8Zfw8RQMeta?.name ?? "lang-purchase-start",
    path: startcQ_45LYa01IMWGPxVDLchgPtW5tXw_XfDahlRb8Zfw8RQMeta?.path ?? "/:lang?/purchase/start",
    props: startcQ_45LYa01IMWGPxVDLchgPtW5tXw_XfDahlRb8Zfw8RQMeta?.props ?? false,
    meta: startcQ_45LYa01IMWGPxVDLchgPtW5tXw_XfDahlRb8Zfw8RQMeta || {},
    alias: startcQ_45LYa01IMWGPxVDLchgPtW5tXw_XfDahlRb8Zfw8RQMeta?.alias || [],
    redirect: startcQ_45LYa01IMWGPxVDLchgPtW5tXw_XfDahlRb8Zfw8RQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkout0LirXmLfDNjCA46kHz0RaC31U9WK6gt_UldI9PUAS8UMeta?.name ?? "lang-service-booking-checkout",
    path: checkout0LirXmLfDNjCA46kHz0RaC31U9WK6gt_UldI9PUAS8UMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkout0LirXmLfDNjCA46kHz0RaC31U9WK6gt_UldI9PUAS8UMeta?.props ?? false,
    meta: checkout0LirXmLfDNjCA46kHz0RaC31U9WK6gt_UldI9PUAS8UMeta || {},
    alias: checkout0LirXmLfDNjCA46kHz0RaC31U9WK6gt_UldI9PUAS8UMeta?.alias || [],
    redirect: checkout0LirXmLfDNjCA46kHz0RaC31U9WK6gt_UldI9PUAS8UMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koP_hcV2dFYxTvmDc_GaJ5QCMt5BspUCgk67GoNoXBbNwMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koP_hcV2dFYxTvmDc_GaJ5QCMt5BspUCgk67GoNoXBbNwMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koP_hcV2dFYxTvmDc_GaJ5QCMt5BspUCgk67GoNoXBbNwMeta?.props ?? false,
    meta: koP_hcV2dFYxTvmDc_GaJ5QCMt5BspUCgk67GoNoXBbNwMeta || {},
    alias: koP_hcV2dFYxTvmDc_GaJ5QCMt5BspUCgk67GoNoXBbNwMeta?.alias || [],
    redirect: koP_hcV2dFYxTvmDc_GaJ5QCMt5BspUCgk67GoNoXBbNwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationw8Lr_plCy0twBnhhpS0yT2_45uemOqM2EV2V4gXHHJ8yMMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationw8Lr_plCy0twBnhhpS0yT2_45uemOqM2EV2V4gXHHJ8yMMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationw8Lr_plCy0twBnhhpS0yT2_45uemOqM2EV2V4gXHHJ8yMMeta?.props ?? false,
    meta: confirmationw8Lr_plCy0twBnhhpS0yT2_45uemOqM2EV2V4gXHHJ8yMMeta || {},
    alias: confirmationw8Lr_plCy0twBnhhpS0yT2_45uemOqM2EV2V4gXHHJ8yMMeta?.alias || [],
    redirect: confirmationw8Lr_plCy0twBnhhpS0yT2_45uemOqM2EV2V4gXHHJ8yMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationWEAuVU5NOEmUuq3NwS7P75SfC_JkvNU8eK_45EEenoq0QMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationWEAuVU5NOEmUuq3NwS7P75SfC_JkvNU8eK_45EEenoq0QMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationWEAuVU5NOEmUuq3NwS7P75SfC_JkvNU8eK_45EEenoq0QMeta?.props ?? false,
    meta: confirmationWEAuVU5NOEmUuq3NwS7P75SfC_JkvNU8eK_45EEenoq0QMeta || {},
    alias: confirmationWEAuVU5NOEmUuq3NwS7P75SfC_JkvNU8eK_45EEenoq0QMeta?.alias || [],
    redirect: confirmationWEAuVU5NOEmUuq3NwS7P75SfC_JkvNU8eK_45EEenoq0QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyer9vn8N9E61Zsu5pcHGimQqkzEaNoFS1tNgxnapg9MaqwMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyer9vn8N9E61Zsu5pcHGimQqkzEaNoFS1tNgxnapg9MaqwMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyer9vn8N9E61Zsu5pcHGimQqkzEaNoFS1tNgxnapg9MaqwMeta?.props ?? false,
    meta: buyer9vn8N9E61Zsu5pcHGimQqkzEaNoFS1tNgxnapg9MaqwMeta || {},
    alias: buyer9vn8N9E61Zsu5pcHGimQqkzEaNoFS1tNgxnapg9MaqwMeta?.alias || [],
    redirect: buyer9vn8N9E61Zsu5pcHGimQqkzEaNoFS1tNgxnapg9MaqwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerofHTsD0PTYgbe8q0AUMIQaX7nTFTtJlS3HGx270gqqYMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerofHTsD0PTYgbe8q0AUMIQaX7nTFTtJlS3HGx270gqqYMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerofHTsD0PTYgbe8q0AUMIQaX7nTFTtJlS3HGx270gqqYMeta?.props ?? false,
    meta: buyerofHTsD0PTYgbe8q0AUMIQaX7nTFTtJlS3HGx270gqqYMeta || {},
    alias: buyerofHTsD0PTYgbe8q0AUMIQaX7nTFTtJlS3HGx270gqqYMeta?.alias || [],
    redirect: buyerofHTsD0PTYgbe8q0AUMIQaX7nTFTtJlS3HGx270gqqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentC_45NUhPqmWyPupgiHOBgvt40ps7pZfcCc7nGS_453lxWjkMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentC_45NUhPqmWyPupgiHOBgvt40ps7pZfcCc7nGS_453lxWjkMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentC_45NUhPqmWyPupgiHOBgvt40ps7pZfcCc7nGS_453lxWjkMeta?.props ?? false,
    meta: paymentC_45NUhPqmWyPupgiHOBgvt40ps7pZfcCc7nGS_453lxWjkMeta || {},
    alias: paymentC_45NUhPqmWyPupgiHOBgvt40ps7pZfcCc7nGS_453lxWjkMeta?.alias || [],
    redirect: paymentC_45NUhPqmWyPupgiHOBgvt40ps7pZfcCc7nGS_453lxWjkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengeL3_45KkAYf_45qQkw54ciHhGG_HrDkzcNGeP4X4bGVM4EPwMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengeL3_45KkAYf_45qQkw54ciHhGG_HrDkzcNGeP4X4bGVM4EPwMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengeL3_45KkAYf_45qQkw54ciHhGG_HrDkzcNGeP4X4bGVM4EPwMeta?.props ?? false,
    meta: challengeL3_45KkAYf_45qQkw54ciHhGG_HrDkzcNGeP4X4bGVM4EPwMeta || {},
    alias: challengeL3_45KkAYf_45qQkw54ciHhGG_HrDkzcNGeP4X4bGVM4EPwMeta?.alias || [],
    redirect: challengeL3_45KkAYf_45qQkw54ciHhGG_HrDkzcNGeP4X4bGVM4EPwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentMEiUVvHUAS2HBIkMMVsPgIaZueqbxc2N7wIgeCbndzoMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentMEiUVvHUAS2HBIkMMVsPgIaZueqbxc2N7wIgeCbndzoMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentMEiUVvHUAS2HBIkMMVsPgIaZueqbxc2N7wIgeCbndzoMeta?.props ?? false,
    meta: paymentMEiUVvHUAS2HBIkMMVsPgIaZueqbxc2N7wIgeCbndzoMeta || {},
    alias: paymentMEiUVvHUAS2HBIkMMVsPgIaZueqbxc2N7wIgeCbndzoMeta?.alias || [],
    redirect: paymentMEiUVvHUAS2HBIkMMVsPgIaZueqbxc2N7wIgeCbndzoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/gran-canaria/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/gran-canaria/agaete/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/gran-canaria/las-palmas-de-gran-canaria/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/gran-canaria/maspalomas/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/gran-canaria/mogan/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/gran-canaria/san-agustin-maspalomas/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/gran-canaria/san-bartolome-de-tirajana/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/gran-canaria/telde/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/malaga/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/malaga/benalmadena/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/malaga/estepona/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/malaga/fuengirola/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/malaga/malaga/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 14",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/malaga/marbella/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 15",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/malaga/mijas/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 16",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/malaga/ronda/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 17",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/malaga/torremolinos/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 18",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/tenerife/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 19",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/tenerife/adeje/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 20",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/tenerife/arona/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 21",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/tenerife/guia-de-isora/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 22",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/tenerife/playa-de-las-americas/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 23",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/tenerife/puerto-de-la-cruz/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 24",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/tenerife/san-cristobal-de-la-laguna/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 25",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/tenerife/san-miguel-de-abona/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 26",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/tenerife/santa-cruz-de-tenerife/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.name ?? "Spa by location landing: 27",
    path: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.path ?? "/pt/spas/tenerife/santa-ursula/",
    props: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.props ?? false,
    meta: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta || {},
    alias: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.alias || [],
    redirect: SpaList_46pageo4jJH2fv0bYVTYT5fZ7_45z__45p6FEQcJZdTwE7GRnj0uEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/spa-the-flag/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/higueron-spa/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-spacio-10-marylanza-suites/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-azules-nivaria/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 16",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 17",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 18",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 19",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 20",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/alum-spa-aguamarina-suites/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 21",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 22",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/calma-spa/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 23",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 24",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/spa-hotel-monarque-fuengirola-park/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 25",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/the-ritz-carlton-spa/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 26",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 27",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 28",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 29",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 30",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 31",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 32",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 33",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-best-tenerife/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 34",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/spa-paradisus/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 35",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 36",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 37",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/hard-rock-spa-marbella/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 38",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 39",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/centro-wellness-nirama-by-baobab-suites/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 40",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 41",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 42",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-best-semiramis/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 43",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 44",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 45",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 46",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 47",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 48",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/malaga/mood-spa/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 49",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 50",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 51",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-sensations-mirador/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 52",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/body-care-labranda-spa-wellness/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 53",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 54",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 55",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 56",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 57",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.name ?? "Spa landing: 58",
    path: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/",
    props: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.props ?? false,
    meta: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta || {},
    alias: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.alias || [],
    redirect: SpaDetail_46pageILVaboza0cO6gkxu3LCQQj6LzFERgcrCUCPEaW2XoxcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/terapia-da-madeira/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/massagem-as-costas-e-circuito-de-spa-para-duas-pessoas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/circuito-termal-para-dois-2-horas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/circuito-de-spa-1-hora/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/massagem-desportiva/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/massagem-com-pedras-vulcanicas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-by-alina-vriel/massagem-dorsal-e-circuito-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/reflexologia-dos-pes-circuito-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/massagem-anti-stress-circuito-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/circuito-massagem-oriental-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/massagem-aromatica-circuito-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/tratamento-anti-envelhecimento-kobido-circuito-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/pacote-de-beleza-para-maos-e-pes-degustacao-de-cha/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/pacote-romantico-massagem-spa-almoco/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/massagem-relaxante-para-dois-com-cava-de-chocolate-circuito-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/la-brise-spa/massagem-wellness-circuito-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/mencey-signature-ritual-115-min-com-circuito-termal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/massagem-aromatica-anti-stress-45-min-com-circuito-termal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/tratamento-facial-hydraforce-source-marine-55min-com-circuito-termico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/especial-dia-dos-namorados-serenity-love/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/dia-da-familia-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/massagem-de-assinatura-mencey-55-min-com-circuito-termal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/circuito-termico-duplo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/gastrospa-segunda-feira-a-sexta-feira/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/circuito-termico-individual/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/stone-therapy-55-com-circuito-termico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/sprunch-com-circuito-termico-sabados-e-domingos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/lomi-lomi-nui-55-min-com-circuito-termico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/massagem-anti-stress-55-min-com-circuito-termico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/massagem-pos-laboral-de-30-min-com-circuito-termal-de-segunda-a-quinta-feira/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/sentidos-noite/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/manicura-e-pedicura-expresso-com-acesso-ao-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/brunch-sem-circuito-termico-sabados-e-domingos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/ritual-indiano-e-spa-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/experiencia-relaxante-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/citrinos-de-bem-estar-relaxantes-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/spa-privado-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/spa-privado-com-massagem-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/momento-spa-para-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-vital-suites/daypass-spa-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/massagem-relaxante-a-cabeca-pescoco-e-ombros/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/massagem-aromatica-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/pele-de-seda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/envolvimento-corporal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/terapia-geotermica-com-pedras/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/massagem-relaxante-aos-pes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/a-arte-de-criar/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/massagem-dinamica-de-recuperacao-corporal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/massagem-terapeutica-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/tratamento-de-limpeza-facial-purificante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/peeling-corporal-com-cristais-marinhos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-the-flag/ritual-anti-stress/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/banhos-de-imersao-em-gelo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/platinum-beach-massage/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/cartao-oferta-montante-200e/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/banho-de-gelo-massagem-desportiva-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/pacote-de-circuito-de-spa-com-champanhe-e-chocolates/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/pack-experiencia-duo-25/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/pacote-de-pressoterapia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/banho-de-gelo-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/pacote-de-experiencia-unica-de-relaxamento-spa-massagem-de-relaxamento-50/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/banho-de-gelo-25-min-de-massagem-desportiva-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/pacote-de-experiencias-para-duplas-romanticas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/pack-experiencia-hidratacao-profunda-spa-tratamento-facial-com-acido-hialuronico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/massagem-aromatica-de-relaxamento-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/massagem-aromatica-de-relaxamento-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/tratamento-facial-anti-envelhecimento-com-acido-hialuronico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/higueron-spa/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/massagem-anti-stress/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/circuito-spa-10-sessoes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/massagem-anti-stress-duplo-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/anthelia-signature-massage-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/circuito-hidrowellness/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/oriental-55-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/especifico-localizado-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/gastro-spa-circuito-termal-almoco/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/gastro-spa-circuito-termal-massagem-almoco/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/spa-familiar/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/sprunch-spa-brunch/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/momento-spa-e-massagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-spacio-10-marylanza-suites/circuito-de-spa-acesso-ao-ginasio/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-spacio-10-marylanza-suites/circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-spacio-10-marylanza-suites/aromaterapia-massagem-de-relaxamento/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-spacio-10-marylanza-suites/tratamento-facial-personalizado/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-spacio-10-marylanza-suites/exfoliante-de-pele-de-corpo-silk-40mins/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-spacio-10-marylanza-suites/pacote-wellness-para-duas-pessoas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-spacio-10-marylanza-suites/massagem-muscular-profunda-completa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-spacio-10-marylanza-suites/massagem-terapeutica-costas-pescoco-e-ombros/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/massagem-personalizada-50/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/drenagem-linfatica/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/massagem-personalizada-25/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/pele-de-seda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/reflexologia-dos-pes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/spa-relax-2-horas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/circuito-thalassa-1-hora/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/ritual-vincci-sun/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/massagem-de-40-minutos-e-2-horas-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/massagem-de-20-minutos-e-2-horas-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/massagem-celta-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/massagem-abhyanga/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/bronzeado-rapido-e-de-longa-duracao/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/noite-doce-no-be-aloe/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-nas-canarias/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/experiencia-nocturna/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/tratamento-lifting-vitamina-c/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/terapia-do-chocolate/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/be-aloe-massagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/serenity-eco-massagem-anti-stress-com-oleo-100-natural/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/especial-dia-do-pai/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-nas-canarias-ilhas-canarias-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/aloe-piscina-e-jantar/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/be-aloe-pool-food/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/experiencia-sensorial-sublime-e-personalizada/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/circuito-de-bem-estar-be-aloe/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/voucher-be-aloe-welness-para-duas-pessoas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/ritual-da-futura-mama/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/be-aloe-wellness-yoga-e-pequeno-almoco/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/vale-de-oferta-de-150e/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/massagem-de-costas-pescoco-e-ombros/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/vale-de-oferta-de-100-euros/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/vale-de-oferta-de-200-euros/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/vale-de-oferta-de-300-euros/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/vale-de-oferta-de-50-euros/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/circuito-spa-massagem-50-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/spa-romantico-privado-para-2-pessoas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/ritual-do-parque-cristobal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/massagem-a-sua-escolha-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/massagem-de-relaxamento-para-as-futuras-mamas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/massagem-jelly-beans-para-raparigas-e-rapazes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/pacote-o-meu-pai-e-eu/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/pacote-relax-spa-e-massagem-nas-costas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/circuito-de-spa-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/experiencia-spa-para-criancas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/pacote-a-minha-mama-e-eu/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/massagem-relaxante-do-rosto-pescoco-e-decote-cerveja-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/exclusivity-beer-massage-55min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-beer-spa-para-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-beer-spa-com-degustacao-e-harmonizacao/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-beer-spa-massagem-15-minutos-residente-nas-ilhas-canarias-e-necessario-um-comprovativo-de-residencia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-beer-spa-massagem-15-minutos-casais-residente-nas-ilhas-canarias-e-necessario-um-comprovativo-de-residencia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/exclusivity-beer-massage-55min-residente-nas-ilhas-canarias-e-necessario-um-comprovativo-de-residencia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-beer-spa-massagem-25-minutos-casal-residente-nas-ilhas-canarias-e-necessario-um-comprovativo-de-residencia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/exclusivity-beer-massage-55min-casal-residente-nas-canarias-e-necessario-comprovativo-de-residencia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-beer-spa-casais-residente-nas-ilhas-canarias-e-necessario-um-comprovativo-de-residencia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-beer-spa-residente-nas-ilhas-canarias-e-necessario-um-comprovativo-de-residencia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-beer-spa-massagem-55-minutos-casal-residente-nas-ilhas-canarias-e-necessario-um-comprovativo-de-residencia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-beer-spa-massagem-15-minutos-sala-comum-1-pessoa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-beer-spa-massagem-localizada-25min-sala-comum-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/cerveja-circuito-spa-massagem-15-minutos-sala-comum-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-beer-spa-na-sala-comum/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/exclusivity-beer-massage-25min-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-private-beer-para-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-premium-em-quarto-privado-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/do-oriente-para-o-ocidente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/massagem-relaxante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/massagem-anantara/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/tempo-para-dois/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/massagem-para-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/oasis-de-serenidade/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/massagem-tradicional-tailandesa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/massagem-muscular-profunda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/doce-espera/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/tratamento-facial-personalizado/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/experiencia-anantara-massage-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/viagem-pelos-sentidos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/anantara-spa-tenerife/circuito-de-spa-residente-nas-canarias/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/tratamento-facial-especializado/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/massagem-ayurveda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/spa-privado-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/50-massagem-anti-dor/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/massagem-lomi-lomi-50/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/50-massagem-para-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/spa-privado-massagem-de-aromaterapia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/massagem-de-30-com-aromaterapia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/massagem-de-aromaterapia-50/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/reflexologia-30/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/express-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/spa-privado-e-moet-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/30-massagem-muscular-profunda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/massagem-nas-pernas-cansadas-30/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/massagem-indiana-a-cabeca/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-canarias/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/manicura-com-goma-laca/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/daypass-circuito-termal-almoco-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/equilibrio-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/segredo-de-santa-agueda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/circuito-spa-massagem-40-minutos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/circuito-termal-ritual-reino-da-hungria/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/reino-da-hungria-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/flash-de-beleza/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/cordial-anti-stress/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/relaxante-completo-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/relaxante-parcial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/circuito-spa-tratamento-facial-aquatico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/circuito-de-entrada-externo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/magia-de-perchel-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/daypass-circuito-termal-almoco/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/sonho-de-callao-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/massagem-com-pedras-quentes-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/massagem-balinesa-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/terapeutico-completo-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/terapeutica-parcial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-azules-nivaria/ritual-facial-e-massagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-azules-nivaria/circuito-spa-ritual-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-azules-nivaria/circuito-spa-massagem-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-azules-nivaria/circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-azules-nivaria/circuito-de-spa-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/hidratante-hialuronico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/firmeza/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/duo-reafirmante-para-rosto-e-corpo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/ritual-relaxante-e-aromatico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/massagem-relaxante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/despapequeno-almoco-privado-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-de-aguas-privado-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/buffet-de-spa-privado-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/spa-almoco-privado-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-privado-para-2-90/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/sprunch-com-spa-privado-para-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/spa-jantar-privado-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/almoco-no-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/spa-classico-circuito-de-agua-90/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 228",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/escapadela-romantica-privada-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 229",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/jantar-no-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 230",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/sprunch-spa-brunch-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 231",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/buffet-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 232",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/despayuno/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 233",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/spa-familiar-para-3-pessoas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 234",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/evasao/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 235",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/massagem-muscular-profunda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 236",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/massagem-personalizada/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 237",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/massagem-localizada/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 238",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/ritual-das-canarias/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 239",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/buffet-de-spa-sem-pressa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 240",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-cava-x-2-all-bubbles/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 241",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/aloe-spa-pele-saudavel/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 242",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-terra-wellness/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 243",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/dia-de-luxo-your-spa-daypass/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 244",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-moet-x-2-un-capricho/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 245",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/tapas-spa-pequenos-sabores/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 246",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termico-massagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 247",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/massagem-neurosedativa-com-aromaterapia-relaxante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 248",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamento-facial-colagenio-marinho-incluindo-olhos-natura-bisse/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 249",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamento-facial-lifting-alisar-preencher-afinar-marine-silicium/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 250",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamento-facial-cocoon-anti-poluicao-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 251",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamento-facial-hialuronico-marinho-anti-envelhecimento-ativador-da-juventude/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 252",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/esfoliante-de-bambu-descamacao-hidratacao-corpo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 253",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/massagem-de-relaxamento-com-ouro-e-oleos-essenciais-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 254",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-gastronomia-massagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 255",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/massagem-as-costas-e-pescoco-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 256",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-gastronomia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 257",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 258",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamento-anti-celulitico-massagem-envolvimento-anti-celulitico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 259",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/massagem-desportiva-terapeutica-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 260",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-wellness-center-natural-spa-gala-alexandre/massagem-com-terapia-de-rosas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 261",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/massagem-vedica-60-min-sala-tailandesa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 262",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/futura-mae-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 263",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/ritual-de-hammam/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 264",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/ritual-de-aloe-vera-e-folhas-de-bananeira/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 265",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/desintoxicacao-oxigenante-60min-biologique-recherche/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 266",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/massagem-para-casais-60-minutos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 267",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/massagem-de-relaxamento-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 268",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/day-pass-dia-de-circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 269",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/day-pass-spa-almoco/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 270",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/day-pass-circuito-spa-residente-nas-ilhas-canarias/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 271",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/day-pass-spa-e-brunch-na-bahia-del-duque/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 272",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/massagem-energizante-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 273",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-aguamarina-suites/ar-de-fada/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 274",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-aguamarina-suites/circuito-termal-ritual/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 275",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-aguamarina-suites/circuito-de-spa-massagem-50/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 276",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-aguamarina-suites/massagem-personalizada-50/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 277",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-aguamarina-suites/circuito-spa-massagem-25/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 278",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-aguamarina-suites/massagem-personalizada-25/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 279",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-aguamarina-suites/passagem-do-silencio/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 280",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 281",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-spa-gastronomia-massagem-cocktail/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 282",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-de-spa-massagem-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 283",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-de-spa-massagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 284",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-de-spa-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 285",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-roca-negra/circuito-termal-gastronomia-cocktail/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 286",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/circuito-de-spa-circuito-de-spa-90/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 287",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/circuito-de-spa-circuito-de-spa-de-60/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 288",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/express-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 289",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/ritual-da-pele-de-seda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 290",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/massagem-relaxante-e-calma/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 291",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/ritual-de-desintoxicacao/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 292",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/circuito-spa-massagem-calma-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 293",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/circuito-spa-massagem-calmante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 294",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/circuito-spa-ritual-argan-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 295",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/circuito-spa-ritual-argan/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 296",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/massagem-para-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 297",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 298",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/massagem-as-costas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 299",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/massagem-indiana-a-cabeca/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 300",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/calma-spa/massagem-expresso/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 301",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/limpeza-hidratacao-com-acido-hialuronico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 302",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/terapia-da-madeira-radiofrequencia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 303",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/ritual-para-a-noiva/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 304",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/noite-de-spa-vip/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 305",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/desconexao-para-dois-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 306",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/desconexao-para-duas-pessoas-nao-residentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 307",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/spa-noturno-para-2-pessoas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 308",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/spa-brunch-para-2-pessoas-nao-residente-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 309",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experiencia-aquatica-para-1-pessoa-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 310",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experiencia-de-relaxamento-para-2-pessoas-nao-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 311",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experiencia-aquatica-para-1-pessoa-nao-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 312",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-de-relaxamento-de-60-minutos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 313",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experiencia-mymate-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 314",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/terapia-da-madeira/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 315",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/cavitacao-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 316",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/envolvimento-de-canarios/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 317",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/noite-de-spa-vip-para-2-pessoas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 318",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experiencia-de-relaxamento-para-1-pessoa-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 319",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/desconexao-para-dois-residentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 320",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-de-relaxamento-30-minutos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 321",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experimentar-muito-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 322",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/spa-brunch-para-2-pessoas-residente-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 323",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experiencia-romantica-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 324",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/terapia-por-pressao/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 325",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experiencia-mymate-nao-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 326",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experiencia-de-relaxamento-para-2-pessoas-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 327",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experiencia-aquatica-para-2-pessoas-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 328",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experiencia-de-relaxamento-para-1-pessoa-nao-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 329",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/experiencia-aquatica-para-2-pessoas-nao-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 330",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/domingo-em-familia-domingos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 331",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/circuito-termico-para-criancas-0-5-anos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 332",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/circuito-termico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 333",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/circuito-termico-para-criancas-6-12-anos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 334",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/sexta-feira-da-juventude/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 335",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/spa-noturno/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 336",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/circuito-termico-vip/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 337",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/circuito-termico-para-criancas-13-17-anos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 338",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/aniversario-com-aperitivos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 339",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/bebe-aqua/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 340",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/spa-brunch-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 341",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/spa-brunch-nao-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 342",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-hotel-monarque-fuengirola-park/pack-ritual-para-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 343",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-hotel-monarque-fuengirola-park/relaxamento-com-spa-e-beleza-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 344",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-hotel-monarque-fuengirola-park/bem-estar-com-spa-e-ritual-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 345",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-hotel-monarque-fuengirola-park/piscina-aquecida/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 346",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-hotel-monarque-fuengirola-park/ritual-mediterranean-airs/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 347",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-hotel-monarque-fuengirola-park/circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 348",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/the-ritz-carlton-spa/experiencia-de-bem-estar/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 349",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-ligeira-das-pernas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 350",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/aromaterapia-80/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 351",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/pacote-anti-stress/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 352",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-sueca/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 353",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/aromaterapia-50/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 354",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/experiencia-de-spa-de-4-horas-pacote-gourmet/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 355",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-dois-a-dois/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 356",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-para-as-futuras-mamas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 357",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/envolvimento-corporal-com-aloe-vera/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 358",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/envolvimento-com-lama-do-mar-morto/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 359",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-chirogolf/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 360",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/capricho-de-chocolate/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 361",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-com-pedras/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 362",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/4-horas-de-experiencia-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 363",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/pacote-de-beleza/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 364",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/pacote-de-experiencia-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 365",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-completa-com-aromaterapia-duplo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 366",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/oasis-of-serenity-duplo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 367",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-parcial-relaxante-duplo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 368",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/oasis-de-serenidade/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 369",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/especial-reino-da-hungria-duplo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 370",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/reflexologia-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 371",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/circuito-de-spa-externo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 372",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/circuito-spa-hydra-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 373",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/circuito-spa-peeling-corporal-ritual-sul-das-ilhas-canarias/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 374",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-completa-com-aromaterapia-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 375",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/especial-do-reino-da-hungria/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 376",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/ritual-do-reino-da-hungria-2-horas-de-circuito-termal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 377",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/equilibrio-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 378",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-parcial-relaxante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 379",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-relaxante-de-corpo-inteiro/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 380",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-facial-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 381",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/ritual-oriental-balines-2-horas-de-circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 382",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 383",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-balinesa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 384",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/shiatsu/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 385",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/massagem-ao-pescoco-2-horas-de-circuito-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 386",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-las-salinas/ritual-do-reino-do-egito-2-horas-de-circuito-termal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 387",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/peeling-classico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 388",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/programa-ayurveda-2-dias/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 389",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/casa-de-banho-com-sais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 390",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/banho-com-algas-marinhas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 391",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/ritual-polinesio/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 392",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/banho-com-essencias/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 393",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/banho-indonesio/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 394",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/envolvimento-hidratante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 395",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/lomi-lomi-80m/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 396",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/aromatic-80m/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 397",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/thai-50m/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 398",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/pedras-vulcanicas-50m/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 399",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/ritual-oriental/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 400",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/ritual-do-canario/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 401",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/ritual-do-dragao-dourado/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 402",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/menu-om-in-love-sobremesacava/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 403",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/massagem-dois-a-dois/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 404",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/noite-romantica-e-experiencia-thalasso-4-horas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 405",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/pack-seaweed-adelgacante-corporal-e-remineralizante-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 406",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/aromaterapia-50min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 407",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/ocean-sunset-vista-para-o-mar/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 408",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/experiencia-thalasso-3h-menu-canto-da-natureza/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 409",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/experiencia-thalasso-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 410",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/experiencia-thalasso-1-dia-menu-canto-da-natureza/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 411",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/massagem-para-as-futuras-mamas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 412",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/capricho-de-chocolate/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 413",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/piscina-de-hidroterapia-ocean-view-30min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 414",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/paraiso-oceanico-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 415",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/massagem-anti-stress/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 416",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/massagem-com-pedras/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 417",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/rassoul/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 418",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/massagem-de-tecidos-profundos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 419",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/circuito-de-spa-massagem-e-gastronomia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 420",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/massagem-de-aromaterapia-50-minutos-e-circuito-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 421",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/circuito-las-dunas-deluxe-facial-e-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 422",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/circuito-de-spa-e-pequeno-almoco/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 423",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 424",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/circuito-de-spa-e-massagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 425",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/massagem-com-pedras-quentes-80-minutos-e-circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 426",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/suite-vip-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 427",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/circuito-de-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 428",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/circuito-termal-ritual/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 429",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/ar-de-fada/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 430",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/flor-da-montanha/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 431",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/massagem-tailandesa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 432",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/circuito-de-spa-massagem-50/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 433",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/massagem-personalizada-50/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 434",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/circuito-spa-massagem-25-circuito-spa-massagem-25-circuito-spa-massagem-25-circuito-spa-massagem-25-circuito-spa-massagem-25/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 435",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/massagem-personalizada-25/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 436",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/passagem-do-silencio/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 437",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/alum-spa-amaragua/massagem-com-flores-de-oliveira/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 438",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/viagem-a-rota-das-delicias-circuito-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 439",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 440",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/ritual-hidraluronico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 441",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/viagem-magica-pela-rota-da-seda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 442",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/viagem-ao-eden-tropical-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 443",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/circuito-spa-1-adulto-2-criancas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 444",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/circuito-spa-2-adultos-1-crianca/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 445",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/circuito-spa-1-adulto-1-crianca/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 446",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/massagem-de-tecidos-profundos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 447",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/wellness-ritual-massagem-de-relaxamento-50-para-um-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 448",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/ritual-romantico-do-sol-e-da-lua-circuito-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 449",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 450",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/oasis-de-serenidade-circuito/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 451",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-best-tenerife/experiencia-romantica-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 452",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-best-tenerife/ritual-cleopatra-banho-aromatico-massagem-relaxante-e-massagem-craniana/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 453",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-best-tenerife/spa-2-adultos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 454",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-best-tenerife/spa-familiar-2-adultos-e-1-crianca/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 455",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-best-tenerife/spa-familiar-2-adultos-e-2-criancas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 456",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-paradisus/evasao-e-beleza-com-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 457",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-paradisus/a-fonte-do-bem-estar-com-o-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 458",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-paradisus/vitalidade-absoluta-com-o-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 459",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-paradisus/spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 460",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/circuito-das-termas-de-inagua-hospedes-cordiais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 461",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/ritual-pre-solar/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 462",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/reino-do-egito/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 463",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/oasis-de-serenidade/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 464",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/circuito-das-termas-de-inagua/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 465",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/maes-expectantes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 466",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/fruta-embrulhada/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 467",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/especial-do-reino-da-hungria/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 468",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/tratamento-facial-sea-sensation/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 469",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/massagem-shiatsu/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 470",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/massagem-tailandesa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 471",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/costas-e-pernas-terapeutico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 472",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 473",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/massagem-ayurveda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 474",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/reflexologia-55min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 475",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/peeling-corporal-com-sais-do-mar-morto/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 476",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/massagem-de-corpo-inteiro/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 477",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/suite-time-for-two/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 478",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/suite-de-luxo-time-for-two/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 479",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/operador-suave/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 480",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/wrap-remix/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 481",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/experiencia-spa-pequeno-almoco/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 482",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/momento-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 483",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/paz-interior/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 484",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/momento-spa-e-massagem-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 485",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/sincronia-50/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 486",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/tecido-profundo-50/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 487",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/spa-de-dia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 488",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/experiencia-de-spa-e-jantar-buffet/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 489",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/acesso-ao-circuito-das-termas-cliente-residente/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 490",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/e-a-sua-vez/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 491",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/massagem-com-pastilhas-elasticas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 492",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/rock-spa-tenerife/enfrentar-a-musica/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 493",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/massagem-45-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 494",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/massagem-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 495",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/espaco-aquatico-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 496",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/espaco-aquatico-e-templario-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 497",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/ritual-ashanti-em-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 498",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/pacote-especial-para-casais-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 499",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/pack-ritual-para-casais-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 500",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/spa-relax-em-casal-30min-de-massagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 501",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/terapia-do-chocolate-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 502",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/spa-e-brunch/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 503",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/programa-anti-stress-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 504",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/pacote-romantico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 505",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/spa-relax-com-30-min-de-massagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 506",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/centro-wellness-nirama-by-baobab-suites/ritual-de-aromaterapia-para-dois/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 507",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-facial-kobido/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 508",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/centro-wellness-nirama-by-baobab-suites/limpeza-facial-classica/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 509",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-champi/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 510",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-abhyanga/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 511",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/ritual-facial-e-corporal-para-gueixas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 512",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 513",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/massagem-ayurvedica-para-gravidas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 514",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/circuito-spa-privado-massagem-de-relaxamento-ou-descontratacao/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 515",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/ritual-facial-detox/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 516",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/ritual-facial-vitamina-c/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 517",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/ritual-corporal-e-facial-de-chocolaterapia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 518",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/ritual-corporal-tropical-de-esfoliacao-e-hidratacao/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 519",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/circuito-spa-privado-pequeno-almoco/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 520",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/circuito-de-spa-privado/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 521",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-del-palacete/ritual-corporal-al-andalus/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 522",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/aula-de-yoga-pilates-qigong-2-pax/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 523",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/aula-de-yoga-pilates-qigong-1-pax/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 524",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/hidratacao-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 525",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/abama-total-wellness/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 526",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/massagem-bio-energetica/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 527",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/massagem-de-pressao-profunda-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 528",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/tratamento-facial-marine-purity/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 529",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/experiencia-de-desintoxicacao-pura/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 530",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/ritual-das-pedras-quentes-do-canario/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 531",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/massagem-sandara-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 532",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/massagem-balinesa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 533",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/experiencia-ayurvedica/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 534",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/especial-black-friday-massagem-balinesa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 535",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/circuito-das-aguas-2-pessoas-hospedes-alojados-no-hotel/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 536",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/sandara-wellness-spa/circuito-de-agua-cliente-externo-2-pessoas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 537",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-best-semiramis/spa-2-adultos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 538",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-best-semiramis/spa-familiar-2-adultos-e-1-crianca/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 539",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-best-semiramis/spa-familiar-2-adultos-e-2-criancas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 540",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-best-semiramis/spa-massagem-relaxante-e-champanhe-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 541",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-best-semiramis/spa-e-massagem-familiar-2-adultos-e-1-crianca/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 542",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/circuito-spa-premium/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 543",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/circuito-spa-peeling/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 544",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/reflexologia-podal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 545",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/pureza-ritual/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 546",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/massagem-ao-por-do-sol-no-egito/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 547",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/massagem-relaxante-80-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 548",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/massagem-relaxante-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 549",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 550",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/viagem-ao-eden-tropical/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 551",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/ritual-de-romance-sol-e-lua/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 552",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/viagem-a-provenca/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 553",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/massagem-de-tisue-profundo-80-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 554",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/massagem-de-tisue-profundo-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 555",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/capricho-ritual-dos-deuses/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 556",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/ritual-do-esplendor-radiante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 557",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/viagem-magica-pela-rota-da-seda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 558",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/ritual-da-brisa-de-ganesha/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 559",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/viagem-aos-vinhedos-da-toscana/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 560",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termal-ritual-intensivo-de-algas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 561",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termal-ritual-magico-do-teide/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 562",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termal-ritual-secreto-da-india/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 563",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termal-massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 564",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termico-massagem-descontratante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 565",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termal-cava-e-chocolate/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 566",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termal-ritual-afrodite/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 567",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termal-massagem-personalizada/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 568",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termal-massagem-ayurveda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 569",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termal-massagem-de-relaxamento/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 570",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termal-tratamento-facial-de-base/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 571",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termal-reflexologia-podal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 572",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/circuito-termico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 573",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/ritual-soul-of-naiad-para-ele/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 574",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/naiad-wellness-center/ritual-soul-of-naiad-para-ela/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 575",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/energizador-40-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 576",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/detox-40-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 577",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/massagem-sueca/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 578",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/tecido-profundo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 579",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/terapia-das-pedras/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 580",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/reflexologia-dos-pes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 581",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/reforco-da-vitamina-c/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 582",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/essencias-e-sentidos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 583",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/energizador-55-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 584",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/detox-55-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 585",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/spa-sensations-marbella-coral-beach/circuito-hidrowellness/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 586",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/segredo-de-amor/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 587",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/mas-mae-feliz/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 588",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/mas-tecido-profundo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 589",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/pedras-quentes-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 590",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/entrada-do-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 591",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/bilhete-spa-para-criancas-5-15-anos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 592",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 593",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/mas-anti-stress/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 594",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/mas-anti-stress-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 595",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/cuidados-corporais-uli-massagem-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 596",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/mas-bebe-relax/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 597",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/cuidados-com-o-corpo-uli-massagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 598",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/indibar-alivio-das-pernas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 599",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/massagem-relaxante-e-renovadora-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 600",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 601",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/shirodhara-abhyanga-ayurvedico/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 602",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/peeling-e-massagem-cocooning/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 603",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/indibar-corpo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 604",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/assinatura-mediterranica-lifting-anti-aging/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 605",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/fitoterapia-ayurvedica-pinda-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 606",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/perola-preciosa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 607",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/remedio-anti-stress-para-as-costas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 608",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/mediterraneo-bliss-rosto-corpo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 609",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/indibar-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 610",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/noite-das-velas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 611",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/circuito-de-spa-2-horas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 612",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/malaga/mood-spa/tecido-profundo-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 613",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/experiencia-vip-cabine-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 614",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/express-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 615",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/ritual-de-maderoterapia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 616",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/experiencia-air-bio-spa-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 617",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/air-bio-spa-privado-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 618",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/circuito-spa-privado-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 619",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/experiencia-bio-spa-110/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 620",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/bio-massagem-relaxante-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 621",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/experiencia-bio-spa-110-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 622",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/experiencia-bio-spa-85/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 623",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/circuito-spa-com-oasis-bio-120-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 624",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/circuito-spa-familiar-60-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 625",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/bio-massagem-para-criancas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 626",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/bio-massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 627",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/experiencia-de-spa-privada/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 628",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/experiencia-familiar-privada-pais-e-filhos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 629",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/experiencia-de-cabina-vip/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 630",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/ritual-bio-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 631",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/ritual-de-luxo-bio-spa/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 632",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/spa-privado-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 633",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/manicure-senza/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 634",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/spa-privado-casal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 635",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/experiencia-bio-spa-com-massagem-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 636",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/experiencia-bio-spa-com-massagem-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 637",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/bio-reflexologia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 638",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/express-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 639",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/tratamento-facial-calmante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 640",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/bio-massagem-relaxante-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 641",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/massagem-bio-muscular-profunda-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 642",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/bio-massagem-relaxante-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 643",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/bio-massagem-para-criancas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 644",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/bio-massagem-pre-natal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 645",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/bio-massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 646",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-hotel-gf-isabel/massagem-bio-muscular-profunda-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 647",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-mirador/manha-deluxe-pequeno-almoco-com-circuito-termal-120min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 648",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-mirador/circuito-hidrowellness/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 649",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-mirador/gastro-spa-almoco-circuito-de-aguas-massagem-55-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 650",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-mirador/gastro-spa-almoco-circuito-de-aguas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 651",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-mirador/manha-deluxe-pequeno-almoco-circuito-de-aguas-massagem-55-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 652",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-mirador/manha-deluxe-pequeno-almoco-circuito-de-aguas-massagem-30-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 653",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-mirador/gastro-spa-almoco-circuito-de-aguas-massagem-30-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 654",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-mirador/depois-do-trabalho-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 655",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/body-care-labranda-spa-wellness/est-tratamento-facial-hidratante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 656",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/body-care-labranda-spa-wellness/mas-mae-feliz/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 657",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/body-care-labranda-spa-wellness/mas-tecido-profundo/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 658",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/body-care-labranda-spa-wellness/cuidados-com-o-corpo-uli-massagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 659",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/body-care-labranda-spa-wellness/mas-bebe-relax/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 660",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/body-care-labranda-spa-wellness/segredo-de-amor/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 661",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/body-care-labranda-spa-wellness/pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 662",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/body-care-labranda-spa-wellness/mas-anti-stress/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 663",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/anti-estresse/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 664",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/massagem-de-assinatura-sabila/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 665",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/terapia-do-casulo-wraps/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 666",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/terapia-com-pedras/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 667",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/prodigia-oceanica/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 668",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/serenidade/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 669",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/tecido-profundo-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 670",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/essencias-e-sentidos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 671",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/reforco-de-vitamina-c/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 672",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/forca-prodigio-do-oceano/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 673",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/oceano-intenso/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 674",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/excellence-marine-collagen-lift/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 675",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/cataplasma-ayurveda-royal-thai-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 676",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/tecido-profundo-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 677",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/lomi-lomi-nui/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 678",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/peeling/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 679",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/resugencia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 680",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/passe-de-dia-spa-massagem-25min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 681",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/spa-massagem-almoco-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 682",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/ritual-de-assinatura-sabila/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 683",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/circuito-hydrowellness/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 684",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/spa-massagem-almoco/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 685",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/vale-de-oferta-de-50-euros/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 686",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/vale-de-oferta-de-150e/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 687",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/vale-de-oferta-de-100-euros/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 688",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/vale-de-oferta-de-200-euros/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 689",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/vale-de-oferta-de-300-euros/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 690",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/spa-no-radisson-blu-resort-75-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 691",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/spa-e-massagem-privados/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 692",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/contractura-muscular-localizada/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 693",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-aromasoul-aromaterapia-50min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 694",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/spa-romance-para-dois/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 695",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-para-as-futuras-mamas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 696",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/mi-mama-y-yo-especial-para-raparigas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 697",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/ritual-das-canarias-80-min-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 698",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/envolvimento-de-aloe-vera-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 699",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/pacote-para-dois-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 700",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-com-ursinhos-de-goma/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 701",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/o-meu-pai-e-eu-especial-para-criancas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 702",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-as-costas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 703",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-a-sua-escolha-50min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 704",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-ayurveda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 705",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/radisson-blu-resort-gran-canaria/massagem-lomi-lomi/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 706",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/experiencia-bio-spa-com-massagem-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 707",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/experiencia-bio-spa-com-massagem-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 708",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/bio-reflexologia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 709",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/express-facial-1/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 710",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/tratamento-facial-calmante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 711",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/massagem-bio-muscular-profunda-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 712",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/bio-massagem-relaxante-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 713",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/bio-massagem-relaxante-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 714",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/bio-massagem-com-pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 715",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/bio-massagem-pre-natal/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 716",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/bio-massagem-para-criancas/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 717",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/massagem-bio-muscular-profunda-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 718",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/pacote-experiencia-aromaterapia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 719",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/dia-de-gloria/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 720",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/massagem-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 721",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/momentos-romanticos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 722",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/massagem-50-min-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 723",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/massagem-ao-ar-livre-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 724",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/experiencia-de-harmonia/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 725",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/pacote-especial-essencial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 726",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/massagem-de-relaxamento-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 727",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/massagem-de-relaxamento-25-min-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 728",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/ayurveda-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 729",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/ayurveda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 730",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/pacote-especial-thalasso/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 731",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/niagara/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 732",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/circuito-de-relaxamento-1-entrada/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 733",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/neurosedativo-com-aromaterapia-relaxante/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 734",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/oxigenacao-facial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 735",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/esfoliante-de-bambu/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 736",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/sal-grosso-cobertura-de-chocolate/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 737",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/massagem-lomi-lomi/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 738",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/massagem-as-costas-e-ao-pescoco-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 739",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/spalopia-com-circuito-de-spa-massagem-e-buffet-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 740",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/spalopia-com-circuito-de-spa-e-buffet-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 741",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/spalopia-com-circuito-de-relaxamento-para-2/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 742",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/experiencia-diamond-rose/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 743",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/ouro-e-oleos-essenciais-relaxantes-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 744",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/pedras-quentes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 745",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/chocolate-relaxante-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 746",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/ayurveda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 747",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/ritual-facial-de-beleza-30-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 748",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/banheira-de-hidromassagem/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 749",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/vouchers-de-massagem-relaxante-25-min-10sessoes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 750",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/vale-de-massagem-50-min-10-sessoes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 751",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/vale-de-fangoterapia-10-sessoes/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 752",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/tratamento-hyalu-procollagen/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 753",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/tratamento-nu-skin/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 754",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/pacote-especial-essencial/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 755",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/descasque-de-cristais-com-flores/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 756",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/um-dia-na-gloria/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 757",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/massagem-ao-ar-livre/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 758",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/massagem-50-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 759",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/especial-dia-dos-namorados/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 760",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/massagem-50-min-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 761",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/piscina-fitness/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 762",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/momentos-romanticos/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 763",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/experiencia-thalasso/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 764",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/piscina-fitness-para-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 765",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/terapia-de-lama-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 766",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/thalgo-relax/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 767",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/massagem-de-relaxamento-25-min/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 768",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/massagem-de-relaxamento-25-min-casais/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.name ?? "Service landing: 769",
    path: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.path ?? "/pt/spas/gran-canaria/thalasso-gloria-amadores/ayurveda/",
    props: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.props ?? false,
    meta: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta || {},
    alias: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.alias || [],
    redirect: ServiceDetail_46pageK1fPBFmxGJB97NOurxqZcoXqOQ_HqhoHieWoCFHA88sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-00ca6768-be5f-49eb-a853-2d0576c5060f/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]